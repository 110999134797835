<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {clipboardHelper} from "../../../../helpers/clipboard-helper";

/**
 * Allegro Connect Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('allegro.connect.title'),
          active: true
        }
      ]
    },

    async loadShop() {
      const shopId = this.getSelectedShopId

      const result = await axios.get(`/shop/${shopId}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shop = result.data;
      this.form.shopId = this.shop.id;
    },

    connect() {
      this.submitted = true;

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.form);

      axios.post(`/allegro`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.$bvToast.toast(this.$t("allegro.connect.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.selectedShop = {}
        this.form = {
          shopId: "",
          clientId: "",
          clientSecret: "",
          accountName: "",
          accountTaxNumber: ""
        }

        this.submitted = false;
        window.location = result.data.redirectUrl;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

  },

  computed: {
    clipboardHelper() {
      return clipboardHelper
    },
    apiAllegro() {
      return process.env.VUE_APP_ALLEGRO_INTEGRATION
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  data() {
    return {
      submitted: false,
      shop: {},

      form: {
        shopId: "",
        clientId: "",
        clientSecret: "",
        accountName: "",
        accountTaxNumber: ""
      }
    }
  },

  validations: {
    form: {
      shopId: {required},
      clientId: {required},
      clientSecret: {required},
      accountName: {required},
      accountTaxNumber: {required}
    }
  },

  async created() {
    await this.loadShop()
  }

}

</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('allegro.connect.title')"/>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="needs-validation">
                  <div class="row">
                    <div v-if="shop" class="col-md-12">
                      <div class="form-group">
                        <p>{{ $t('allegro.connect.shop') }} <b>{{ shop.name }}</b></p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="client-id">Client Id</label>
                        <input id="client-id" v-model="form.clientId" :class="{ 'is-invalid': this.submitted && $v.form.clientId.$error }" class="form-control"
                               type="text"/>
                        <div v-if="submitted && $v.form.clientId.$error" class="invalid-feedback">
                          <span v-if="!$v.form.clientId.required">{{ $t('message.required') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="client-secret">Client Secret</label>
                        <input id="client-secret" v-model="form.clientSecret" :class="{ 'is-invalid': submitted && $v.form.clientSecret.$error }" class="form-control"
                               type="text"/>
                        <div v-if="submitted && $v.form.clientSecret.$error" class="invalid-feedback">
                          <span v-if="!$v.form.clientSecret.required">{{ $t('message.required') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">{{ $t('allegro.connect.account-name') }}</label>
                        <input id="name" v-model="form.accountName" :class="{ 'is-invalid': submitted && $v.form.accountName.$error }" class="form-control"
                               type="text"/>
                        <div v-if="submitted && $v.form.accountName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.accountName.required">{{ $t('message.required') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tax">{{ $t('allegro.connect.tax') }}</label>
                        <input id="tax" v-model="form.accountTaxNumber" :class="{ 'is-invalid': submitted && $v.form.accountTaxNumber.$error }" class="form-control"
                               type="text"/>
                        <div v-if="submitted && $v.form.accountTaxNumber.$error" class="invalid-feedback">
                          <span v-if="!$v.form.accountTaxNumber.required">{{ $t('message.required') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-right">
                    <b-button class="w-sm ml-1" variant="primary" @click="connect">{{
                        $t('allegro.connect.button')
                      }}
                    </b-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h4>{{ $t('allegro.integration.title') }}</h4>
                <p>{{ $t('allegro.integration.subtitle.1') }} <a href="https://apps.developer.allegro.pl" target="_blank" class="badge badge-success badge-pill font-size-12">{{ $t('allegro.integration.subtitle.2') }}</a></p>
                <p>{{ $t('allegro.integration.subtitle.3') }}</p>

                <div class="table-responsive py-3">
                  <table class="table table-bordered">
                    <tbody>
                    <tr>
                      <th>{{ $t('allegro.integration.table.application-name') }}</th>
                      <td>{{ $t('allegro.integration.table.variables.application-name') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('allegro.integration.table.application-type') }}</th>
                      <td>{{ $t('allegro.integration.table.variables.application-type') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('allegro.integration.table.uri') }}</th>
                      <td><b>{{ apiAllegro }}</b>
                        <button @click="clipboardHelper.copy(apiAllegro)" class="btn btn-primary btn-sm mx-2">
                          <i class="mdi mdi-clipboard"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('allegro.integration.table.application-permissions') }}</th>
                      <td>{{ $t('allegro.integration.table.variables.application-permissions') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <p>{{ $t('allegro.integration.info') }} <button class="btn btn-success w-sm ml-1" disabled> {{ $t('allegro.integration.add-button') }}</button></p>
                <p>{{ $t('allegro.integration.warning') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>